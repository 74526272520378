import React, { Component, Suspense } from 'react';
import {
  // HashRouter,
  Route,
  Routes,
  //Switch
} from 'react-router-dom';
import './scss/style.scss';
import { jwtDecode } from 'jwt-decode';
import PrivateRoute from './Common/PrivateRoute';
import { Spinner } from 'react-bootstrap';
//import PrivateRoutes from './Common/test';

const loading = (
  <div className="pt-3 text-center">
    <h3> Loading ...</h3>
    <Spinner className="spinner " animation="border" role="status" style={{ width: '3rem', height: '3rem' }} />
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));
//const Profile = React.lazy(() => import('./SpectraView/Profile/Profile'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
//const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() =>
  import('./views/pages/page500/Page500').then((module) => ({ default: module.Page500 })),
);
class App extends Component {
  checkTokenExpirationMiddleware = () => {
    console.log('App Login check');
    const token = localStorage.getItem('token');
    //console.log(token);
    try {
      if (token === null || jwtDecode(token).exp < Date.now() / 1000) {
        localStorage.removeItem('token');
        //localStorage.clear();
        //sessionStorage.clear();
        return false;
        // window.location.replace('/#/login');
      } else {
        return true;
      }
    } catch {
      console.log('Invalid token');
      return false;
    }
  };
  componentDidUpdate() {
    console.log('componentDidUpdate');
    //this.checkTokenExpirationMiddleware();
  }
  componentDidMount() {
    console.log('componentDidMount');
    // this.checkTokenExpirationMiddleware();
  }
  render() {
    return (
      // <HashRouter>
      <Suspense fallback={loading}>
        {/* <Switch> */}
        <Routes>
          <Route exact path="/login" name="Login Page" element={<Login />} />
          {/* <Route exact path="/register" name="Register Page" element={<Register />} /> */}
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="*" element={<PrivateRoute cb={this.checkTokenExpirationMiddleware} />}>
            {/* <Route exact path="Profile" name="Profile" element={<Profile></Profile>} /> */}
            {/* <Route exact path="/" name="Home" element={<DefaultLayout />} /> */}
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Route>
          {/* <Route path="/login" element={<Login />} /> */}
        </Routes>
        {/* </Switch> */}
        {/* <Routes>
          <Route path="*" element={<PrivateRoutes cb={this.checkTokenExpirationMiddleware} />} />
        </Routes> */}
      </Suspense>
      // </HashRouter>
    );
  }
}

export default App;
